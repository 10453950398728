import { useMutation, useQuery } from 'react-query';
import { clearUserAuth, updateUserAuth } from 'redux/slices/userSlice';
import useToolkit from 'utils/hooks/misc/useToolkit';
export interface UpdatePasswordProps {
  old: string;
  new: string;
  new_confirmation: string;
}
export enum AuthTypes {
  TWO_FA = '2fa',
  EMAIL = 'email',
}
export interface AuthenticatePayload {
  type: AuthTypes;
  code: string | number;
}
export interface SetupPayload {
  type: AuthTypes;
}

const useSettingsQueries = () => {
  const { axiosInstance, userId, toastError, toastSuccess, logout } = useToolkit();
  const useUpdatePassword = () => {
    return useMutation({
      mutationKey: ['updatePassword'],
      mutationFn: async (payload: UpdatePasswordProps) => {
        const response = await axiosInstance.patch(
          `endUsers/password/${userId}`,
          payload
        );
        return response.data;
      },
      onError: (error: Record<string, any>) => {
        toastError(error?.response?.data?.message);
      },
    });
  };
  const useVerifyPassword = () => {
    return useMutation({
      mutationKey: ['verifyPassword'],
      mutationFn: async (password: string) => {
        const response = await axiosInstance.post('endUsers/verify-password', {
          password,
        });
        return response.data;
      },
      onError: (error: Record<string, any>) => {
        toastError(error?.response?.data?.message);
      },
      onSuccess: () => {

      },
    });
  };
  const useDeactivateAccount = () => {
    return useMutation({
      mutationKey: ['deactivateAccount'],
      mutationFn: async (password: string) => {
        const response = await axiosInstance.patch('request-account-deactivation', {
          password,
        });
        return response.data;
      },
      onError: (error: Record<string, any>) => {
        toastError(error?.response?.data?.message);
      },
      onSuccess: () => {
        // toastSuccess('Verified');
        logout()

      },
    });
  };
  const useGetAvailableAuthenticator = () => {
    const { userId } = useToolkit()
    return useQuery({
      queryKey: ['getAvailableAuths', userId],
      queryFn: async () => {
        const response = await axiosInstance.get('authenticator/available');
        return response.data;
      },
      onError: (error: Record<string, any>) => {
        toastError(error?.response?.data?.message);
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!userId
    });
  };
  const useShowUserAuthenticator = (retrigger?: boolean) => {
    const { userId } = useToolkit()

    return useQuery({
      queryKey: ['getUserAuth', retrigger, userId],
      queryFn: async () => {
        const response = await axiosInstance.get('authenticator');
        return response.data;
      },
      onError: (error: Record<string, any>) => {
        toastError(error?.response?.data?.message);
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    });
  };
  const useActivateAuthentication = () => {
    const { dispatch } = useToolkit()

    return useMutation({
      mutationKey: ['activateAuthenticator'],
      mutationFn: async (payload: AuthenticatePayload) => {
        const response = await axiosInstance.patch(
          'authenticator/verify',
          payload
        );
        if (response?.data?.status === true) {
          dispatch(updateUserAuth(response?.data?.content?.data))
        }
        return response.data;
      },
      onError: (error: Record<string, any>) => {
        toastError(error?.response?.data?.message);
      },
    });
  };


  const useSetupAuthentication = () => {
    return useMutation({
      mutationKey: ['setupAuthenticator'],
      mutationFn: async (payload: SetupPayload) => {
        const response = await axiosInstance.post(
          'authenticator/setup',
          payload
        );
        return response.data;
      },
      onError: (error: Record<string, any>) => {
        toastError(error?.response?.data?.message);
      },
    });
  };


  const useTransactionPinSetUp = () => {
    return useMutation({
      mutationKey: ['Transaction PinSetUp'],
      mutationFn: async (payload: any) => {
        const response = await axiosInstance.post(
          'pin',
          payload
        );
        return response.data;
      },
      onError: (error: Record<string, any>) => {
        toastError(error?.response?.data?.message);
      },
    });
  };



  const useDeactivateAuthentication = () => {
    const { dispatch } = useToolkit()

    return useMutation({
      mutationKey: ['deactivateAuthenticator'],
      mutationFn: async (payload: AuthenticatePayload) => {
        const response = await axiosInstance.patch(
          'authenticator/deactivate',
          payload
        );

        if (response?.data?.status === true) {
          dispatch(clearUserAuth())
        }

        return response.data;
      },
      onError: (error: Record<string, any>) => {
        toastError(error?.response?.data?.message);
      },
    });
  };
  const useChangePin = () => {
    return useMutation({
      mutationKey: ['reset pin'],
      mutationFn: async (payload: any) => {
        const response = await axiosInstance.patch(
          'pin/reset',
          payload
        );
        return response.data;
      },
      onError: (error: Record<string, any>) => {
        toastError(error?.response?.data?.message);
      },
    });
  };
  const useChangePinReset = () => {
    return useMutation({
      mutationKey: ['pinreset'],
      mutationFn: async (payload: any) => {
        const response = await axiosInstance.patch(
          'pin',
          payload
        );
        return response.data;
      },
      onError: (error: Record<string, any>) => {
        toastError(error?.response?.data?.message);
      },
    });
  };
  const useResendEmailCode = () => {
    return useQuery({
      queryKey: ['resendEmail'],
      queryFn: async () => {
        const response = await axiosInstance.get(
          'authenticator/set-email-code'
        );
        return response.data;
      },
      onError: (error: Record<string, any>) => {
        toastError(error?.response?.data?.message);
      },
      enabled: false,
    });
  };

  const useCancelAuth = () => {
    return useMutation({
      mutationKey: ['cancelAuth'],
      mutationFn: async () => {
        const response = await axiosInstance.patch('authenticator/cancel', {
          type: AuthTypes.TWO_FA,
        });
        return response.data;
      },
      onError: (error: Record<string, any>) => {
        toastError(error?.response?.data?.message);
      },
      onSuccess: () => {
        toastSuccess("Auth canceled")
      }
    });
  };
  const useGetTierProfile = () => {
    const { userId } = useToolkit()
    return useQuery({
      queryKey: ['getProfileInfo', userId],
      queryFn: async () => {
        const response = await axiosInstance.get('tier-kyc/tier-profile-info')
        return response.data
      },
      onError: (error: Record<string, any>) => {
        toastError(error?.response?.data?.message);
      },
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    })
  }
  const useSecurityQuestion = () => {
    return useQuery({
      queryKey: ['securityquestion'],
      queryFn: async () => {
        const response = await axiosInstance.get(
          'security_question/question_list'
        );
        return response?.data.content.data;
      },
      onError: (error: Record<string, any>) => {
        // toastError(error?.response?.data?.message);
      },
      refetchOnMount: true,
    });
  };
  const useGetRandomSecurityQuestion = () => {
    return useQuery({
      queryKey: ['RandomSecurityquestion'],
      queryFn: async () => {
        const response = await axiosInstance.get(
          'security_question/random_select'
        );
        return response?.data.content.data;
      },
      onError: (error: Record<string, any>) => {
        // toastError(error?.response?.data?.message);
      },
      refetchOnMount: true,
    });
  };
  const useSetUpSecurityQuestionPin = () => {
    return useMutation({
      mutationKey: ['setupAuthenticator'],
      mutationFn: async (payload: any) => {
        const response = await axiosInstance.post(
          'security_question',
          payload
        );
        return response.data;
      },
      onError: (error: Record<string, any>) => {
        toastError(error?.response?.data?.message);
      },
    });
  };

  const useGetTransactionLimits = (cur?: string) => {
    const { userId } = useToolkit()
    return useQuery({
      queryKey: ['getLimit', cur],
      queryFn: async () => {
        const response = await axiosInstance.get(`wallet/${userId}/${cur}`);
        return response.data
      },
      refetchOnWindowFocus: false
    })
  }

  return {
    useChangePin,
    useChangePinReset,
    useGetRandomSecurityQuestion,
    useSetUpSecurityQuestionPin,
    useSecurityQuestion,
    useUpdatePassword,
    useVerifyPassword,
    useGetAvailableAuthenticator,
    useShowUserAuthenticator,
    useActivateAuthentication,
    useSetupAuthentication,
    useDeactivateAuthentication,
    useResendEmailCode,
    useCancelAuth,
    useGetTierProfile,
    useDeactivateAccount,
    useGetTransactionLimits,
    useTransactionPinSetUp
  };
};

export default useSettingsQueries;

