import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { handleRequestError } from "./errorHandler";

const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
const defaultHeaders = { "Content-Type": "application/json" };

const fetchCryptoTransactions: any = createAsyncThunk(
   "transactions/fetchTransactions",

   async ({ userToken, asset, pageNo }: any) => {
      try {
         const headers = { ...defaultHeaders, Authorization: `Bearer ${userToken}` };

         const { data } = await axios.get(`${baseUrl}trade/user/assets/asset-transaction?cur=${asset}&pageNo=${pageNo}&pageSize=10`, { headers });
         return data?.content?.data;
      } catch (error) {
         handleRequestError(error);
      }
   }
);

const fetchAssetDetails: any = createAsyncThunk(
   "asset/fetchDetails",

   async ({ userToken, asset }: any) => {
      try {
         const headers = { ...defaultHeaders, Authorization: `Bearer ${userToken}` };

         const { data } = await axios.get(`${baseUrl}trade/user/assets?asset=${asset}`, { headers });
         return data?.content?.data[0];
      } catch (error) {
         handleRequestError(error);
      }
   }
);

const fetchPortfolioChart: any = createAsyncThunk(
   "asset/fetchChart",

   async ({ userToken, asset, filter_mode, cur }: any) => {
      try {
         const headers = { ...defaultHeaders, Authorization: `Bearer ${userToken}` };
         const { data } = await axios.post(`${baseUrl}trade/user/assets/asset-graph`, { asset, filter_mode, cur }, { headers });
         const response = data?.content?.data;

         const xAxis = response?.map((item: any) => item.balance_after)
         const yAxis = response?.map((item: any) => item.balance_after - item.balance_before)

         if (response?.length) {
            return { xAxis, yAxis };
         } else {
            return { xAxis: [], yAxis: [] }
         }
      } catch (error) {
         handleRequestError(error);
      }
   }
);

const fetchUserAssets: any = createAsyncThunk(
   "asset/fetchAssets",

   async ({ userToken }: any) => {
      try {
         const headers = { ...defaultHeaders, Authorization: `Bearer ${userToken}` };

         const { data } = await axios.get(`${baseUrl}trade/user/assets`, { headers });
         const response = data?.content?.data;
         const active = response?.active_assets?.map((item: any) => ({ ...item, isActive: true }))
         const inactive = response?.inactive_asset?.map((item: any) => ({ ...item, isActive: false }))
         const assets = [...active, ...inactive];

         return assets;
      } catch (error) {
         handleRequestError(error);
      }
   }
);

const fetchCryptoAddresses: any = createAsyncThunk(
   "asset/fetchAddresses",

   async ({ userToken, asset, network }: any) => {
      try {
         const headers = { ...defaultHeaders, Authorization: `Bearer ${userToken}` };
         const { data } = await axios.get(`${baseUrl}trade/user/address-book?asset=${asset}&network=${network}`, { headers });
         return data?.content?.data;
      } catch (error) {
         handleRequestError(error);
      }
   }
);

const createCryptoBeneficiary: any = createAsyncThunk(
   "asset/createCryptoBeneficiary",

   async ({ userToken, payload }: any) => {
      try {
         const headers = { ...defaultHeaders, Authorization: `Bearer ${userToken}` };
         const { data } = await axios.post(`${baseUrl}trade/user/address-book`, payload, { headers });
         return data?.content?.data;
      } catch (error) {
         handleRequestError(error);
      }
   }
);

export {
   fetchAssetDetails,
   fetchUserAssets,
   fetchPortfolioChart,
   fetchCryptoTransactions,
   fetchCryptoAddresses,
   createCryptoBeneficiary
}