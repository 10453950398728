import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CryptoRateProps } from 'utils/hooks/trade/useCryptoProps';
import { fetchCryptoTransactions, fetchAssetDetails, fetchPortfolioChart, fetchUserAssets, fetchCryptoAddresses } from './helpers/crypto';

type cryptoState = {
   selectedCryptoAsset: any;
   isBuyCryptoOpen: boolean;
   isSellCryptoOpen: boolean;

   isSendCryptoOpen: boolean;
   isReceiveCryptoOpen: boolean;
   ratesCopy: CryptoRateProps | null;

   cryptoTxnLoading: boolean;
   cryptoTxnList: any;
   assetDetailsLoading: boolean;

   assetDetails: any;
   graphLoading: boolean;
   pinSetupOpen: boolean;
   graphData: { xAxis: any, yAxis: any };

   errorCount: boolean;
   assetsLoading: boolean;
   cryptoAssets: any;

   cryptoAddressLoading: boolean;
   cryptoAddressData: any;
}

const initialState: cryptoState = {
   selectedCryptoAsset: null,
   isBuyCryptoOpen: false,
   isSellCryptoOpen: false,

   isSendCryptoOpen: false,
   isReceiveCryptoOpen: false,
   ratesCopy: null,

   cryptoTxnLoading: false,
   cryptoTxnList: null,
   assetDetailsLoading: false,

   assetDetails: null,
   graphLoading: false,
   pinSetupOpen: false,
   graphData: { xAxis: null, yAxis: null },

   errorCount: false,
   assetsLoading: false,
   cryptoAssets: [],

   cryptoAddressLoading: false,
   cryptoAddressData: null,
}

const cryptoSlice = createSlice({
   name: 'crypto',
   initialState,
   reducers: {

      selectCryptoAsset: (state, { payload }: PayloadAction<any>) => {
         return {
            ...state,
            selectedCryptoAsset: payload,
         };
      },

      toggleBuyCryptoModal: (state) => {
         return {
            ...state,
            isBuyCryptoOpen: !state.isBuyCryptoOpen,
         };
      },

      toggleSellCryptoModal: (state) => {
         return {
            ...state,
            isSellCryptoOpen: !state.isSellCryptoOpen,
         };
      },

      toggleSendCryptoModal: (state) => {
         return {
            ...state,
            isSendCryptoOpen: !state.isSendCryptoOpen,
         };
      },

      toggleReceiveCryptoModal: (state) => {
         return {
            ...state,
            isReceiveCryptoOpen: !state.isReceiveCryptoOpen,
         };
      },

      updateCryptoRate: (state, { payload }: PayloadAction<CryptoRateProps>) => {
         return {
            ...state,
            ratesCopy: payload,
         };
      },

      openPinSetup: (state) => {
         return {
            ...state,
            pinSetupOpen: true,
         };
      },

      closePinSetup: (state) => {
         return {
            ...state,
            pinSetupOpen: false,
         };
      },

      resetCryptoParams: (state) => {
         return {
            ...state,
            isBuyCryptoOpen: false,
            isSellCryptoOpen: false,
            isSendCryptoOpen: false,
            isReceiveCryptoOpen: false,
            ratesCopy: null,
            pinSetupOpen: false
         };
      },

   },

   extraReducers: (builder) => {
      builder
         .addCase(fetchCryptoTransactions.pending, (state) => {
            state.cryptoTxnLoading = true;
            state.cryptoTxnList = null;
            state.errorCount = false;
         })
         .addCase(fetchCryptoTransactions.fulfilled, (state, { payload }) => {
            state.cryptoTxnLoading = false;
            state.cryptoTxnList = payload;
            state.errorCount = false;
         })
         .addCase(fetchCryptoTransactions.rejected, (state) => {
            state.cryptoTxnLoading = false;
            state.cryptoTxnList = null;
            state.errorCount = true;
         });


      builder
         .addCase(fetchAssetDetails.pending, (state) => {
            state.assetDetailsLoading = true;
            state.assetDetails = null;
         })
         .addCase(fetchAssetDetails.fulfilled, (state, { payload }) => {
            state.assetDetailsLoading = false;
            state.assetDetails = payload;
         })
         .addCase(fetchAssetDetails.rejected, (state) => {
            state.assetDetailsLoading = false;
            state.assetDetails = null;
         });


      builder
         .addCase(fetchPortfolioChart.pending, (state) => {
            state.graphLoading = true;
            state.graphData = { xAxis: null, yAxis: null };
         })
         .addCase(fetchPortfolioChart.fulfilled, (state, { payload }) => {
            state.graphLoading = false;
            state.graphData = payload;
         })
         .addCase(fetchPortfolioChart.rejected, (state) => {
            state.graphLoading = false;
            state.graphData = { xAxis: null, yAxis: null };
         });


      builder
         .addCase(fetchUserAssets.pending, (state) => {
            state.assetsLoading = true;
            state.cryptoAssets = [];
         })
         .addCase(fetchUserAssets.fulfilled, (state, { payload }) => {
            state.assetsLoading = false;
            state.cryptoAssets = payload;
         })
         .addCase(fetchUserAssets.rejected, (state) => {
            state.assetsLoading = false;
            state.cryptoAssets = [];
         });


      builder
         .addCase(fetchCryptoAddresses.pending, (state) => {
            state.cryptoAddressLoading = true;
            state.cryptoAddressData = [];
         })
         .addCase(fetchCryptoAddresses.fulfilled, (state, { payload }) => {
            state.cryptoAddressLoading = false;
            state.cryptoAddressData = payload;
         })
         .addCase(fetchCryptoAddresses.rejected, (state) => {
            state.cryptoAddressLoading = false;
            state.cryptoAddressData = [];
         });
   },
});

export const {
   selectCryptoAsset,
   toggleBuyCryptoModal,
   toggleSellCryptoModal,
   toggleSendCryptoModal,
   toggleReceiveCryptoModal,
   updateCryptoRate,
   resetCryptoParams,
   openPinSetup,
   closePinSetup,
} = cryptoSlice.actions

export default cryptoSlice.reducer