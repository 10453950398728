type SpinnerProps = {
	msg?: string | JSX.Element;
	rdr?: boolean;
	rise?: boolean;
	isSmall?: boolean;
	customColor?: string;
};

import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { ReactNode } from "react";
import { RiseLoader } from "react-spinners";

function Spinner({ msg, rise, isSmall, customColor }: SpinnerProps) {
	const spinIcon = (
		<LoadingOutlined
			style={{ fontSize: isSmall ? 30 : 45, color: customColor || "#48BBED" }}
			spin
		/>
	);

	return (
		<div className="text-center w-full flexed flex-col h-full py-3">
			{rise ? (
				<RiseLoader color={customColor || "#48BBED"} className="mt-7 mb-4" />
			) : (
				<Spin indicator={spinIcon} />
			)}
			<p
				className={`pry-clr tracking-wide mt-3 med ${isSmall ? "text-sm" : "text-lg"
					}`}>
				{msg || "Processing..."}
			</p>
		</div>
	);
}

export default Spinner;
