import { Tooltip as Tip } from "antd";
import { BsInfoCircleFill } from "react-icons/bs";

type Props = {
	text: string;
	isBlue?: boolean;
	upc?: boolean;
	size?: string | number;
};

function Tooltip({ text, isBlue, upc, size }: Props) {
	return (
		<Tip title={text}>
			<BsInfoCircleFill
				size={size || "1.2rem"}
				className={`curp font-semibold text-justify pry-text ${upc && "uppercase"
					}`}
			/>
		</Tip>
	);
}

export default Tooltip;
