import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useDispatch, useSelector, batch } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import { type RootState, type AppDispatch } from '../../../redux/store';
import moment from 'moment';
import { useToasts } from 'react-toast-notifications';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Checkbox, Dropdown, Empty, Menu } from 'antd';
import axios from 'axios';
import { BiLogOutCircle } from 'react-icons/bi';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { logoutUser } from 'redux/slices/userSlice';
import { clearDashboardData, clearWalletFlag } from 'redux/slices/dashboardSlice';
import { RiFileCopyFill } from 'react-icons/ri';
import {
  AUTH_ROUTES,
  DASHBOARD_ROUTES,
  LANDING_ROUTES,
  ONBOARDING_ROUTES,
} from 'utils/appData/appRoutes';
import { QwidButton } from 'components';
import { toggleStatementModal } from 'redux/slices/transactionsSlice';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import { clearOnbState } from 'redux/slices/onboardingSlice';
import { clearOnbDataState } from 'redux/slices/onboardingDataSlice';
import { clearSendflowRecords } from 'redux/slices/sendflowSlice';
import { LocalStorageKeys } from 'components/product-update';
import { currencySymbols } from './currencySymbols';
import CryptoJS from 'crypto-js';
import { clearLayoutSlice } from 'redux/slices/layoutSlice';
import { toast } from 'sonner';
import { IoClose } from 'react-icons/io5';


// To be moved to appropriate place
export enum BeneficiaryType {
  INDIVIDUAL = 'Individual',
  BUSINESS = 'Business',
}

// Beneficiary and sign mapping
const BENEFICIARY_SIGN = {
  [BeneficiaryType.BUSINESS]: 'B',
  [BeneficiaryType.INDIVIDUAL]: 'C',
};

export const beneficiaryCategoryOptions = [{ title: 'Bank', value: 'BANK' }];
export const beneficiaryTypeOptions = [
  { title: 'Individual Beneficary', value: 'Individual' },
  { title: 'Business Beneficiary', value: 'Business' },
];

export const beneficiaryTypeOptionNew = [
  { value: 1, title: 'An Individual' },
  { value: 2, title: 'A Business' },
];

const useToolkit = () => {
  const useAppDispatch: () => AppDispatch = useDispatch;
  const dispatch = useAppDispatch();
  const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
  const { addToast } = useToasts();
  const router = useRouter();
  const { user, plainUserInfo, fcmToken, deviceId, isUserPinSet, isLoggedIn, userAuth } =
    useAppSelector((state) => state.user);
  const [termsChecked, setTermsChecked] = useState(false);
  const { selectedVCard: vcard } = useAppSelector((state) => state.virtuals);
  const { referralLink, userTier } = useAppSelector((state) => state.dashboard);

  const globalCardDetailsHidden = vcard?.detailsHidden === true;

  const spacifyCardNumber = (number: string) => {
    if (globalCardDetailsHidden) {
      return `${number?.substring(0, 4)} **** **** ${number?.substring(12)}`;
    } else {
      return number?.length
        ? number.toString().replace(/\B(?=(\d{4})+(?!\d))/g, ' - ')
        : '';
    }
  };

  const isIOS = () => {
    return (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform) ||
      /iPad|iPhone|iPod/.test(navigator.userAgent) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  };

  const getCardExpiry = (month: string, year: string) => {
    if (month === undefined || year === undefined) {
      return '** / **';
    }

    const formatted = `${month} / ${year?.substring(2)}`;

    return formatted;
  };

  const StatementDownloadBtn = useCallback(
    ({ loading }: { loading: boolean }) => (
      <QwidButton
        disabled={loading}
        onClick={() => dispatch(toggleStatementModal())}
        darkBlueBg
        text={<span className="px-3">Download Statement</span>}
      />
    ),
    [dispatch]
  );

  const EmptyDataUI = ({ desc }: { desc?: string }) => <Empty />;

  const openPdfUrl = (id: string) =>
    window.open(
      `https://${isStagingEnv ? 'test' : isPilotEnv ? 'partners-pilot' : 'partners'
      }.qwid.io/receipt/${id}`,
      '_blank'
    );

  // analytics
  const gtmID = process.env.NEXT_PUBLIC_GTAG_MGR_ID;
  const pixelID = process.env.NEXT_PUBLIC_FBOOK_PIXEL_ID;

  // axios interceptor
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;
  const token = process.env.NEXT_PUBLIC_TOKEN;
  const blogbaseUrl = process.env.NEXT_PUBLIC_BLOGBASE_URL;
  // production base url
  // const baseUrl = process.env.NEXT_PUBLIC_LBASE_URL;
  // const token = process.env.NEXT_PUBLIC_LTOKEN;

  const isValidUser = user !== null;
  const trxPinExists = user?.transactionPin === true || isUserPinSet;

  const userObj: PlainUserProps = plainUserInfo?.emailVerify
    ? plainUserInfo
    : user?.endUser
      ? user?.endUser[0]
      : user?.enduser[0];

  const businessInfoObj = user?.businessInfo;

  const userFullName = `${userObj?.lastName} ${userObj?.firstName}`;

  const userNamesAreValid =
    userObj?.lastName?.length && userObj?.firstName?.length;

  const userEmail = user?.endUser
    ? user?.endUser[0]?.email
    : user?.enduser[0]?.email;

  const isPersonalAccount = user?.endUser
    ? user?.endUser[0]?.userType === 1
    : user?.enduser[0]?.userType === 1;

  const emailIsVerified = user?.endUser
    ? user?.endUser[0]?.emailVerify === 1
    : user?.enduser[0]?.emailVerify === 1;

  const isBusinessAccount = user?.endUser
    ? user?.endUser[0]?.userType === 2
    : user?.enduser[0]?.userType === 2;

  const userToken = user?.loginSession[0]?.loginToken;

  const removeLastClosedTimestampFromLS = () => {
    localStorage.removeItem(LocalStorageKeys.LastClosedTimestamp);
  };
  const cleanUpOnLogout = () => {

    localStorage.removeItem('eleganza')
    localStorage.removeItem('mollusca')
    batch(() => {
      dispatch(logoutUser());
      dispatch(clearOnbState());
      dispatch(clearOnbDataState());
      dispatch(clearSendflowRecords());
      dispatch(clearDashboardData())
      dispatch(clearLayoutSlice());
      removeLastClosedTimestampFromLS();
    });
  };
  const queryClient = useQueryClient()
  const logout = async () => {
    queryClient.invalidateQueries();
    setTimeout(() => {
      cleanUpOnLogout();
    }, 100)


    if (isValidUser) {
      try {
        await axiosInstance.patch(`login/${userId}/${userToken}`, {
          ...(deviceId && { deviceId }),
        });
      } catch (error) {
        return error;
      }
    }
  };

  // metamap
  const testPersonalFID =
    process.env.NEXT_PUBLIC_METAMAP_SANDBOX_INDIVIDUAL_FLOW;
  const testBusinessFID = process.env.NEXT_PUBLIC_METAMAP_SANDBOX_BUSINESS_FLOW;

  const livePersonalFID = process.env.NEXT_PUBLIC_METAMAP_LIVE_INDIVIDUAL_FLOW;
  const liveBusinessFID = process.env.NEXT_PUBLIC_METAMAP_LIVE_BUSINESS_FLOW;

  const { isLiveEnv, isStagingEnv, isLocalEnv, isPilotEnv } = useMemo(() => {
    return {
      isStagingEnv: baseUrl?.includes('test'),
      isLiveEnv: !baseUrl?.includes('test'),
      isPilotEnv: baseUrl?.includes('pilot'),
      isLocalEnv: process.env.NODE_ENV === 'development',
    };
  }, [baseUrl]);

  const isPersonalStaging = isPersonalAccount && isStagingEnv;
  const isBusinessStaging = isBusinessAccount && isStagingEnv;
  const isPersonalLive = isPersonalAccount && !isStagingEnv;
  const isBusinessLive = isBusinessAccount && !isStagingEnv;

  const flowID = isPersonalStaging
    ? testPersonalFID
    : isBusinessStaging
      ? testBusinessFID
      : isPersonalLive
        ? livePersonalFID
        : isBusinessLive
          ? liveBusinessFID
          : null;

  const rerouteToDashboard = () => {
    if (
      user &&
      router.pathname !== AUTH_ROUTES.CHANGE_PASSWORD &&
      router.pathname !== AUTH_ROUTES.BUSINESS_SIGN_UP
    ) {
      router.push(DASHBOARD_ROUTES.DASHBOARD);
    }
  };
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  };

  const axiosBlogInstance = axios.create({
    baseURL: 'https://blog-api.qwid.io/api/',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers,
  });

  axiosInstance.interceptors.request.use(
    (request) => {
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const message = error?.response?.data?.message || error?.message;
      const isString = typeof message == 'string';
      if (
        message?.toLowerCase()?.includes('unauthenticated.') &&
        !error?.request?.responseURL?.includes('/api/login') && !error?.request?.responseURL?.includes('/api/set-noty-token') //confirm with taofik!!!!!!!
      ) {
        router.push(AUTH_ROUTES.SIGN_IN);
        setTimeout(() => {
          cleanUpOnLogout();
        }, 100)
      } else if (isString && message.includes('Token is expired or invalid')) {
        router.push(AUTH_ROUTES.SIGN_IN);
        setTimeout(() => {
          cleanUpOnLogout();
        }, 100)
      } else if (isString && message?.includes('Pending user verification')) {
        router.push(
          isPersonalAccount
            ? isNigPersonalAccount ? ONBOARDING_ROUTES.TIERED_ONBOARDING.ADDITIONAL_INFORMATION : ONBOARDING_ROUTES.PERSONAL.PERSONAL_INFO
            : ONBOARDING_ROUTES.BUSINESS.BUSINESS_TYPE // Todo: revisit this logic
        );
      }
      // toastError(isString ? message : "Something went wrong");
      return Promise.reject(error);
    }
  );

  // nav buttons
  const isHomeRoute: boolean =
    router.pathname === LANDING_ROUTES.PERSONAL_HOMEPAGE;
  const isBusinessRoute: boolean =
    router.pathname === LANDING_ROUTES.BUSINESS_HOMEPAGE;
  const isMobile = window && window.innerWidth < 800;

  const menu = (
    <Menu
      items={[
        { key: '1', label: <Link href="/">Feature One</Link> },
        { key: '2', label: <Link href="/auth/signin">Feature Two</Link> },
      ]}
    />
  );

  const SignInBtn = ({ isForMobile, onClick }: SignInBtnBtnProps) => {
    return (
      <p onClick={onClick} className="font-normal softext t6 curp">
        {isValidUser ? 'Dashboard' : 'Sign In'}
      </p>
    );
  };

  const BlogBtn = ({ isForMobile, onClick, darkText }: BlogBtnProps) => {
    return isForMobile ? (
      <p
        onClick={onClick}
        className={`curp mb-0 t6 tracking-wide trall ${isHomeRoute ? 'blk med' : 'font-normal softext'
          }`}
      >
        Blog
      </p>
    ) : (
      <Link href={LANDING_ROUTES.BLOG_HOME_PAGE}>
        <span
          className={`curp t6 tracking-wide hover:opacitys-80 trall ${darkText ? 'text-black med' : 'text-white'
            } ${isHomeRoute ? 'med opacity-100' : 'font-normal opacity-60'}`}
        >
          Blog
        </span>
      </Link>
    );
  };
  const PersonalBtn = ({
    isForMobile,
    onClick,
    darkText,
  }: PersonalBtnProps) => {
    return isForMobile ? (
      <p
        onClick={onClick}
        className={`curp mb-0 t6 tracking-wide trall ${isHomeRoute ? 'blk med' : 'font-normal softext'
          }`}
      >
        Personal
      </p>
    ) : (
      <Link href="/">
        <span
          className={`curp t6 tracking-wide hover:opacitys-80 trall ${darkText ? 'text-black med' : 'text-white'
            } ${isHomeRoute ? 'med opacity-100' : 'font-normal opacity-60'}`}
        >
          Personal
        </span>
      </Link>
    );
  };

  const BusinessBtn = ({
    isForMobile,
    onClick,
    darkText,
  }: BusinessBtnProps) => {
    return isForMobile ? (
      <p
        onClick={onClick}
        className={`curp mb-0 t6 tracking-wide trall ${isBusinessRoute ? 'blk med' : 'font-normal softext'
          }`}
      >
        Business
      </p>
    ) : (
      <Link href="/business">
        <span
          className={`curp t6 tracking-wide hover:opacitys-80 trall ${darkText ? 'blk med' : 'text-white'
            } ${isBusinessRoute ? 'med opacity-100' : 'font-normal opacity-60'}`}
        >
          Business
        </span>
      </Link>
    );
  };

  const FeaturesBtn = () => {
    return (
      <Dropdown overlay={menu} placement="bottom">
        <span className="curp text-white t6 tracking-wide hover:opacity-80 trall med">
          Features
        </span>
      </Dropdown>
    );
  };

  const LogoutBtn = ({ onClick, isForMobile }: LogoutBtnBtnProps) => {
    return (
      <li
        onClick={onClick}
        className="flect w-full py-3 pl-2 lg:pl-3 curp tracking-wide text-xs t4 lg:t4 hover:shadow-md hover:text-white hover:bg-[#7C7C7C] transition-all duration-200 text-[#252D3E]"
      >
        <BiLogOutCircle
          className={`${isForMobile ? 'mr-3 text-2xl' : 'mr-2.5 text-xl'}`}
        />{' '}
        Log out
      </li>
    );
  };

  // utilities
  const TextBtn = ({ path, text }: TextBtnProps) => {
    return (
      <Link href={`/${path}`}>
        <span className="curp pry-text tracking-wide hover:scale-105 trall med">
          &nbsp; {text}
        </span>
      </Link>
    );
  };

  const HALF_WIDTH_WRAPPER =
    'w-full flexed flex-col md:flex-row md:justify-between space-y-7 md:space-y-0 md:space-x-5';
  const HALF_WIDTH_CHILD = 'w-full md:w-[49%] lg:w-[48%]';

  const copyToClipboard = (item: any, title: string) => {
    navigator.clipboard.writeText(item);
    toastInfo(`${title} Copied`);
  };

  const CopyIcon = ({ item, title }: { item: any; title: string }) => (
    <span className="ml-2">
      <RiFileCopyFill
        onClick={() => {
          navigator.clipboard.writeText(item);
          toastInfo(`${title} Copied`);
        }}
        className="icon-clr text-lg curp"
      />
    </span>
  );

  const PryText = ({ text }: { text: string }) => (
    <span className="pry-text">{text}</span>
  );

  // round up values
  const roundUp = (number: any, decplace: number) => {
    let myRound =
      Math.round(number * Math.pow(10, decplace)) / Math.pow(10, decplace);

    return String(myRound).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  };

  const clearFlag = () => dispatch(clearWalletFlag());

  function avail(value: any) {
    return value || 'N/A';
  }

  function toastSuccess(message: string) {
    return toast.success(message);
    // addToast(`${message}!`, {
    //   appearance: 'success',
    // });
  }

  function Space({ num }: SpaceProps) {
    return (
      <div
        style={{
          padding: `${num}px 0`,
        }}
      />
    );
  }

  function toastError(message: string) {
    return toast.error(message);
    // addToast(`${message}!`, {
    //   appearance: 'error',
    // });
  }

  function toastInfo(message?: string, custom?: any) {
    return toast.info(message);

    // if (custom === true) {
    //   return toast.info(message, {
    //     action: <button className='text-white absolute right-2 text-xl top-1/2 transform -translate-y-1/2'
    //       onClick={() => toast.dismiss()}>
    //       <IoClose />
    //     </button>,
    //   }
    //   )
    // } else {
    //   return addToast(`${message?.length ? message : 'Coming soon'}!`, {
    //     appearance: 'info',
    //   });
    // }
  }

  function toastWarn(message?: string) {
    return addToast(`${message?.length ? message : 'Coming soon'}!`, {
      appearance: 'warning',
    });
  }

  const customToast = ({
    message,
    timeout,
    type,
    placement,
  }: {
    message: string;
    type: 'success' | 'info' | 'error' | 'warning';
    timeout?: number;
    placement?:
    | 'bottom-left'
    | 'bottom-center'
    | 'bottom-right'
    | 'top-left'
    | 'top-center'
    | 'top-right';
  }) => {
    addToast(message, {
      placement: placement || 'top-center',
      appearance: type,
      ...(timeout && { autoDismissTimeout: timeout }),
    });
  };

  const BackActionButton = ({
    route,
    text,
  }: {
    route: string;
    text?: string;
  }) => (
    <div onClick={() => router.push(route)} className="flect curp press">
      <HiOutlineArrowNarrowLeft className="text-lg" />{' '}
      <span className="text-sm ml-2">{text || 'Back'}</span>
    </div>
  );

  const HeadEl = useCallback(
    ({ isLanding, isAuth, isDashboard, isKycPage }: HeadElProps): any => {
      return (
        <Head>
          <title>
            {isLanding || isAuth
              ? 'Qwid | Welcome'
              : isDashboard
                ? 'Qwid | Dashboard'
                : isKycPage
                  ? 'Qwid | KYC / KYB'
                  : 'Qwid'}
          </title>
          <meta name="description" content="Borderless Payments" />
          <meta
            name="facebook-domain-verification"
            content="d6u6jcprfujamx9brtdulbg5cmkl01"
          />
          <link rel="icon" href="/favicon.png" />

          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${gtmID}`}
          ></script>
          {isKycPage && (
            <script defer src="https://web-button.mati.io/button.js"></script>
          )}
          <meta
            itemProp="description"
            content="Empower your business with quick and reliable payment systems. With 99% success rate on transfers, Qwid is your best bet."
          />
        </Head>
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line
    []
  );

  function getSymbol(currencyISO?: string): string {

    return currencyISO ? currencySymbols[currencyISO?.toUpperCase()] : currencyISO || '';
  }

  function getCurrencySymbol(currencyISO?: string): string {

    return getSymbol(currencyISO)
  }
  function formatStatus(status: string) {
    switch (status) {
      case 'success':
        return (
          <p className="mb-0 bg-[#F0F7F0] text-[#17C509] px-2 py-1 med">
            Successful
          </p>
        );
      case 'pending':
        return (
          <p className="mb-0 bg-[#FFF9ED] text-[#BC953B] px-2 py-1 med">
            Pending
          </p>
        );
      case 'failed':
        return (
          <p className="mb-0 bg-[#FDF5F4] text-[#B41600] px-2 py-1 med">
            Failed
          </p>
        );
      default:
        return '';
    }
  }

  const formatTrxAmount = (amount: any) => {
    return amount?.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const formatWalletDropdownBalance = (currencyCode: any, balance: any) => {
    // let symbol;
    // switch (currencyCode) {
    // 	case "EUR":
    // 		symbol = "€";
    // 		break;
    // 	case "USD":
    // 		symbol = "$";
    // 		break;
    // 	case "GBP":
    // 		symbol = "£";
    // 		break;
    // 	case "NGN":
    // 		symbol = "₦";
    // 		break;
    // 	default:
    // 		// Default to USD if an unsupported currency code is provided
    // 		currencyCode = "USD";
    // 		symbol = "$";
    // }

    const formattedBalance = (Math.floor(balance * 100) / 100).toFixed(2);
    return `${getSymbol(currencyCode)}${formattedBalance}`;
  };

  const TermsAgreement = () => (
    <div className="flexed mb-6">
      <Checkbox
        checked={termsChecked}
        onChange={() => setTermsChecked(!termsChecked)}
      >
        I agree to Qwid's
        <span>
          <TextBtn path="terms" text="terms of use" />
        </span>
      </Checkbox>
    </div>
  );

  const userId = userObj?.userId || '';
  const userType = userObj?.userType || '';

  const getTransactionType = (beneficiaryType: BeneficiaryType) =>
    isBusinessAccount
      ? `${BENEFICIARY_SIGN[BeneficiaryType.BUSINESS]}2${BENEFICIARY_SIGN[beneficiaryType]
      }`
      : `${BENEFICIARY_SIGN[BeneficiaryType.INDIVIDUAL]}2${BENEFICIARY_SIGN[beneficiaryType]
      }`;

  const AsteriskLabel = () => (
    <span className="text-asterisk">&nbsp;&nbsp;*&nbsp;&nbsp;</span>
  );

  const camelToSnakeCasing = (camelCaseString: string) => {
    return camelCaseString.replace(
      /[A-Z]/g,
      (match, offset) => `${offset !== 0 ? '_' : ''}${match.toLowerCase()}`
    );
  };

  const handleRequestError = (
    error: any,
    options: {
      warning?: boolean;
      ignoreToast?: boolean;
      customMessage?: string;
      useCustomMessage?: boolean;
    } = {}
  ) => {
    const toastFn = options.warning ? toastWarn : toastError;
    const errorMessage =
      error?.response?.data?.message || options.customMessage;
    const errorArray = error?.response?.data?.errors;
    // if error is due to 401 issues and not during login then there is no need to show it since the user would get redirected to the login page anyways
    if (
      errorMessage?.toLowerCase()?.includes('unauthenticated.') &&
      !error?.request?.responseURL?.includes('/api/login')
    )
      return;
    if (!options.ignoreToast) {
      if (options.customMessage && options.useCustomMessage) {
        toastFn(options.customMessage);
      } else if (Array.isArray(errorArray) && errorArray?.length > 0) {
        errorArray?.map((item: any) => toastFn(item));
      } else if (typeof errorArray !== null && Object.keys(errorArray)?.length > 0) {
        Object.entries(errorArray).forEach(([key, messages]) => {
          const keyInCaps = key.charAt(0).toUpperCase() + key.slice(1);
          if (Array.isArray(messages) && messages.length > 0) {
            toastFn(`${keyInCaps}: ${messages.join(', ')}`);
          }
        })
      } else if (typeof errorArray === 'string' && errorArray?.length > 0) {
        errorArray?.split('<br/>')?.map((item: any) => toastFn(item));
      } else if (errorMessage?.length > 0 && errorMessage?.includes('<br/>')) {
        errorMessage?.split('<br/>')?.map((item: any) => toastFn(item));
      } else if (errorMessage?.length) {
        toastFn(errorMessage || 'Something went wrong');
      } else {
        toastFn('Something went wrong, please retry');
      }
    }
  };
  const toTwoDecimalPlace = (amount: number) =>
    (Math.floor(amount * 100) / 100).toFixed(2);

  const formatBalance = (balance: string) => {
    if (balance) {
      const symbolLastIndex = balance.match(/[^0-9.](?=[0-9.]*$)/)?.index || 0;
      const curSymbol = balance?.slice(0, symbolLastIndex + 1);
      const newBalance = balance.slice(symbolLastIndex + 1);
      const rounded = newBalance.split('.');
      const decimal = rounded[rounded.length - 1];
      const stringValue = parseInt(newBalance);
      return `${curSymbol}${stringValue.toLocaleString()}.${newBalance.includes('.') ? decimal : '00'
        }`;
    }
    return undefined;
  };
  const secretKey = process.env.NEXT_PUBLIC_ELEGANZA

  function encryptData(data: any) {
    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey as string).toString();

    } catch (error) {
      return "encryption error";
    }
  }

  function decryptData(ciphertext: any) {
    try {
      const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey as string);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (error) {
      return null;
    }
  }

  const isNigPersonalAccount = plainUserInfo?.countryCode === 'NG' && isPersonalAccount

  const isTierZeroUser = isNigPersonalAccount && userTier !== null && userTier?.level === 0;
  const isTierOneUser = isNigPersonalAccount && userTier !== null && userTier?.level === 1;
  const isTierTwoUser = isNigPersonalAccount && userTier !== null && userTier?.level === 2;
  const isTierThreeUser = isNigPersonalAccount && userTier !== null && userTier?.level === 3;
  const useNigPersonalRedirect = () => {
    return useEffect(() => {
      if (!isNigPersonalAccount) {
        router.push(DASHBOARD_ROUTES.DASHBOARD);
      }
      // eslint-disable-next-line
    }, [isNigPersonalAccount])
  }

  const useFetchBannerPop = () => {
    const { mutateAsync, data } = useMutation({
      mutationFn: async () => {
        const response = await axiosInstance.post('banner', { platform: 'web' })
        return response.data
      }
    })
    useEffect(() => {
      mutateAsync()
      // eslint-disable-next-line
    }, [])

    return data?.content?.data?.length > 0 ? data?.content?.data[0] : undefined;
  }

  const mfaIsValid = userAuth?.find((item: any) => item.type === '2fa' && item?.status === 1);

  return {
    LogoutBtn,
    PersonalBtn,
    axiosBlogInstance,
    BusinessBtn,
    FeaturesBtn,
    SignInBtn,
    user,
    userId,
    userType,
    userEmail,
    router,
    Image,
    Link,
    useState,
    useRef,
    useEffect,
    HeadEl,
    dispatch,
    useAppSelector,
    userObj,
    TextBtn,
    avail,
    toastSuccess,
    toastError,
    toastInfo,
    Space,
    isMobile,
    moment,
    axiosInstance,
    getCardExpiry,
    isValidUser,
    toastWarn,
    getSymbol,
    formatStatus,
    flowID,
    isPersonalAccount,
    isBusinessAccount,
    TermsAgreement,
    termsChecked,
    useMutation,
    useQuery,
    userFullName,
    globalCardDetailsHidden,
    spacifyCardNumber,
    openPdfUrl,
    BlogBtn,
    emailIsVerified,
    userNamesAreValid,
    isStagingEnv,
    rerouteToDashboard,
    logout,
    clearFlag,
    roundUp,
    businessInfoObj,
    copyToClipboard,
    CopyIcon,
    PryText,
    StatementDownloadBtn,
    isLocalEnv,
    HALF_WIDTH_CHILD,
    HALF_WIDTH_WRAPPER,
    EmptyDataUI,
    isLiveEnv,
    trxPinExists,
    referralLink,
    BackActionButton,
    baseUrl,
    getTransactionType,
    camelToSnakeCasing,
    deviceId,
    fcmToken,
    handleRequestError,
    customToast,
    formatTrxAmount,
    formatWalletDropdownBalance,
    toTwoDecimalPlace,
    plainUserInfo,
    isPilotEnv,
    formatBalance,
    isLoggedIn,
    isIOS,
    getCurrencySymbol,
    encryptData,
    decryptData,
    isTierZeroUser,
    isTierOneUser,
    isTierTwoUser,
    isTierThreeUser,
    isNigPersonalAccount,
    useNigPersonalRedirect,
    useFetchBannerPop,
    userToken,
    userAuth,
    mfaIsValid,
  };
};

export default useToolkit;
