import { store } from "redux/store";
import { toast } from "sonner";
import { logoutUser } from "../userSlice";

const toastFn = (error: string) => {
   toast.error(error);
}

export const handleRequestError = (error: any) => {
   const errorMessage = error?.response?.data?.message;
   const errorArray = error?.response?.data?.errors;

   if (errorMessage?.toLowerCase()?.includes('unauthenticated.')) {
      // window.location.href = "/auth/signin";
      store.dispatch(logoutUser());
   } else if (Array.isArray(errorArray) && errorArray?.length > 0) {
      errorArray?.map((item: any) => toastFn(item));
   } else if (typeof errorArray === 'string' && errorArray?.length > 0) {
      errorArray?.split('<br/>')?.map((item: any) => toastFn(item));
   } else if (errorMessage?.length > 0 && errorMessage?.includes('<br/>')) {
      errorMessage?.split('<br/>')?.map((item: any) => toastFn(item));
   } else if (errorMessage?.length) {
      toastFn(errorMessage || 'Something went wrong');
   } else {
      toastFn('Something went wrong, please retry');
   }
};